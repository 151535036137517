<template>
	  <div class="ns-groupbuy">
	  	<div class="novideo" v-show="!showplayer">
	  		<el-image fit="scale-down" :src="$img(info.images)" lazy ></el-image>
	  		<div class="tip" v-if="info.livestate == 2"><span>{{$lang('view.shop.live.live2')}}</span></div>
	  		<div class="tip" v-if="info.livestate == 0"><span>{{$lang('view.shop.live.live3')}}</span></div>
	  	</div>
	  <!-- 测试-->
		<div class="video-player" v-show="showplayer">
			<div id="palyback">
				<div>
				  <div id="player-container-id" ></div>
				</div>
			</div>
		</div>
		
		<!-- 商品列表 -->
		<div class="ns-groupbuy-box" v-loading="loading">
			<div class="ns-groupbuy-title" v-if="goodsList.length">
				<i class="iconfont icontuangou"></i>
				<span>{{$lang('view.shop.live.live4')}}</span>
			</div>

			<div class="goods-list" v-if="goodsList.length">
				<div class="item" v-for="item in goodsList" :key="item.id">
					<div class="goods" >
						<!-- 商品图片区 -->
						<div class="img"><el-image fit="scale-down" :src="$img(item.goods_image)" lazy @error="imageError(index)"></el-image></div>

						<!-- 商品名称 -->
						<!-- <div class="name">
							<p :title="item.goods_name">{{ item.goods_name }}</p>
						</div> -->

						<!-- 价格展示区 -->
						<!-- <div class="price">
							<div>
								<p>
									<span>{{$lang('view.promotion.groupbuy.groupbuy2')}}</span>
									<span>Ks </span>
									<span class="main_price">{{ item.groupbuy_price }}</span>
								</p>
								<span class="primary_price">Ks {{ item.price }}</span>
							</div>
						</div> -->
					</div>
				</div>
			</div>
			<div v-else><div class="ns-text-align">{{$lang('view.shop.live.live5')}}</div></div>

			
		</div>
	</div>
</template>
 
<script>
import { LiveInfo } from '@/api/live';
import { mapGetters } from 'vuex';
import { TcPlayer } from '@/utils/TcPlayer-2.4.1';
export default {
	name: 'live',
	components: {
	},
    data() {
      return {
		loading: true,
		goodsList: [],
		info:[],
		id:'',
		playurl:'',
		showplayer:false,
        player: null,
        // onswitch: false
      }
    },
	created() {
		this.id = this.$route.path.replace("/shop/live-", "")
		this.getLiveInfo();
		
		setTimeout(function(){
			$this.playBtn(0)
		},0)
	},
    mounted() {
     
    },
 
    methods: {
	
      playVideo() {
		  	let that = this
        document.getElementById('player-container-id').innerHTML = ""
        this.player = new TcPlayer('player-container-id', {
          "m3u8": this.playurl, //请替换成实际可用的播放地址
          "autoplay": true, //iOS下safari浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
          // "coverpic": img,
		  // "controls":'none',
          "width": 'auto', //视频的显示宽度，请尽量使用视频分辨率宽度
          "height": '100%', //视频的显示高度，请尽量使用视频分辨率高度
          "remember": 1,
		  "listener":function(msg){
			  console.log(msg)
			 if(msg.type == 'error'){
				 that.refresh()
			 }
			 
			
		  }
        });
 
    
      },
	  
	  
	  /**
	   * 团购商品
	   */
	  getLiveInfo() {
	  	LiveInfo({
	  		live_id:this.id
	  	})
	  		.then(res => {
	  			console.log(res)
	  			if(res.code == 0){
	  				this.info = res.data;
	  				
	  				if(res.data.livestate == 1){
	  					this.playurl = res.data.liveurl
						this.showplayer = true
						 this.playVideo()
	  				}else{
	  					this.playurl = '';
						this.showplayer = false;
	  				}
	  				this.goodsList = res.data.goods
	  				this.loading = false;
	  			}
	  			
	  		})
	  		.catch(err => {
	  			this.loading = false;
	  			this.$message.error(err.message);
	  		});
	  },
	  refresh() {
	  	this.loading = true;
	  	this.getLiveInfo();
	  },
	  /**
	   * 图片加载失败
	   */
	  imageError(index) {
	  	this.goodsList[index].image = this.defaultGoodsImage;
	  }
 
      // // 切换
      // onchange() {
      //   this.onswitch = !this.onswitch
      // }
    }
  }
</script>
 <style lang="scss" scoped>
 .ns-groupbuy {
 	background: #ffffff;
 	.ns-groupbuy-box {
 		padding-top: 54px;
 		width: $width;
 		margin: 0 auto;
 
 		.ns-groupbuy-title {
 			width: 100%;
 			border-bottom: 1px solid $base-color;
 			padding-bottom: 10px;
 
 			i {
 				font-size: 32px;
 				color: $base-color;
 			}
 
 			span {
 				font-size: 30px;
 				font-family: 'BDZongYi-A001';
 				font-weight: 600;
 				color: $base-color;
 				margin-left: 15px;
 			}
 		}
 	}
 	.goods-list {
 		display: flex;
 		flex-direction: row;
 		flex-wrap: wrap;
 		justify-content: flex-start;
 		margin-top: 45px;
 
 		.item {
 			width: 20%;
 			padding: 0 6px;
 			box-sizing: border-box;
 			margin-bottom: 16px;
 
 			.goods {
 				width: 100%;
 				border: 1px solid #e9e9e9;
 				background-color: #ffffff;
 				overflow: hidden;
 				color: #303133;
 				transition: 0.3s;
 				padding: 10px;
 				box-sizing: border-box;
 				cursor: pointer;
 			}
 		}
 
 		.img {
 			width: 100%;
 			height: 100%;
 
 			.el-image {
 				width: 100%;
 				height: 208px;
 				.el-image__error {
 					width: 100%;
 					height: 100%;
 				}
 			}
 		}
 
 		.price {
 			p {
 				display: flex;
 				align-items: flex-end;
 				height: 24px;
 				color: $base-color;
 				margin: 10px 0 5px;
 
 				span:first-child {
 					font-size: 20px;
 					font-family: 'BDZongYi-A001';
 					font-weight: 600;
 					margin-right: 5px;
 					line-height: 24px;
 				}
 				span:nth-child(2) {
 					line-height: 14px;
 				}
 			}
 
 			.main_price {
 				color: $base-color;
 				font-size: 25px;
 				line-height: 24px;
 				font-weight: 500;
 			}
 
 			.primary_price {
 				text-decoration: line-through;
 				color: $base-color-info;
 				margin-left: 5px;
 			}
 		}
 
 		.name {
 			font-size: 14px;
 			line-height: 1.4;
 			margin-bottom: 5px;
 			white-space: normal;
 			overflow: hidden;
 			
 			p {
 				line-height: 24px;
 				display: -webkit-box;
 				-webkit-box-orient: vertical;
 				-webkit-line-clamp: 2;
 				overflow: hidden;
 				height: 50px;
 			}
 		}
 	}
 }
 </style>
 <style lang="scss">
 .novideo{
 	text-align: center;
 	background: #000;
 	margin-top: 30px;
 	position: relative;
 	.el-image{
 		opacity: .5;
		height: 500px;
 	}
 	.tip{
 		position: absolute;
 		top: 50%;
 		width: 100%;
 		span{
 			background-color: #000;
 			color: #fff;
 			padding: 10px 20px;
 			border-radius: 10px;
 		}
 	}
 }
 
 .ns-groupbuy {
 	.el-carousel {
 		.el-image__inner {
 			width: auto;
 		}
 	}
 	.el-carousel__arrow--right{
 		right: 60px;
 	}
 	
 	.video-player{
		max-height: 600px;
		overflow: hidden;
 	}
 	.video-player,.video-js{
 		width: auto;
 	}
 	.video-js .vjs-big-play-button{
 		top: 40%;
 		left: 45%;
 	}
 }
 </style>
 
<style scoped>
  #palyback {
    width: auto;
    max-height: 500px;
    box-sizing: border-box;
    padding-top: 2rem;
    min-width: 59.375rem;
    position: relative;
  }
  .else {
    width: 400px;
    position: absolute;
    top: 3%;
    right: 2%;
    z-index: 100;
  }
  #player-container-id{
	  height: 500px;
	  width: auto;
  }
 
  .box-title {
    width: 50px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: pink;
    position: absolute;
    bottom: 3%;
    right: 2%;
  }
  .vcp-timeline{
	  display: none;
  }
  .vcp-player video{
	  margin: auto !important;
  }
</style>