import http from "../utils/http"


/**
 * 直播列表
 * @param {object} params
 */
export function LivePage(params) {
    return http({
        url: "/find/api/live/page",
        data: params,
        forceLogin: true
    })
}

/**
 * 商品详情
 * @param {object} params
 */
export function LiveInfo(params) {
    return http({
        url: "/find/api/live/info",
        data: params,
        forceLogin: true
    })
}

/**
 * 商品信息
 * @param {object} params
 */
export function goodsSkuInfo(params) {
    return http({
        url: "/groupbuy/api/goods/info",
        data: params,
        forceLogin: true
    })
}
